// MoldRiskChart.tsx
import React from 'react';
import {
    ScatterChart,
    Scatter,
    CartesianGrid,
    XAxis,
    YAxis,
    Tooltip,
    ResponsiveContainer,
    Customized
} from 'recharts';

const zoneDefinitions = [
    {
        label: 'För torrt',
        fill: '#8cd3ff',
        fillOpacity: 0.2,
        domainPoints: [
            [0, 50],
            [0, 100],
            [4, 100],
            [5, 95],
            [6, 90],
            [7, 85],
            [8, 80],
            [9, 77],
            [10, 74],
            [11, 72],
            [12, 70],
            [15, 67],
            [20, 64],
            [25, 62],
            [30, 60],
            [35, 59],
            [40, 58],
            [50, 57],
            [50, 50],
        ],
        labelPos: [25, 57],
    },
    {
        label: 'Mögeltillväxt möjlig',
        fill: '#ffe600',
        fillOpacity: 0.2,
        domainPoints: [
            [0, 100],
            [4, 100],
            [5, 95],
            [6, 90],
            [7, 85],
            [8, 80],
            [9, 77],
            [10, 74],
            [11, 73],
            [12, 72],
            [15, 70],
            [20, 68],
            [25, 66],
            [30, 65],
            [35, 64],
            [40, 63],
            [50, 62],
            [50, 57],
            [40, 58],
            [35, 59],
            [30, 60],
            [25, 62],
            [20, 64],
            [15, 67],
            [12, 70],
            [11, 72],
            [10, 74],
            [9, 77],
            [8, 80],
            [7, 85],
            [6, 90],
            [5, 95],
            [4, 100],
            [0, 100],
        ],
        labelPos: [25, 65],
    },
    {
        label: 'Tid för tillväxt: 8 veckor',
        fill: '#ff9f00',
        fillOpacity: 0.2,
        domainPoints: [
            [0, 100],
            [4, 100],
            [5, 96],
            [6, 92],
            [7, 88],
            [8, 84],
            [9, 81],
            [10, 78],
            [11, 76],
            [12, 75],
            [15, 74],
            [20, 73],
            [25, 72],
            [30, 71],
            [35, 70],
            [40, 69],
            [50, 68],
            [50, 62],
            [40, 63],
            [35, 64],
            [30, 65],
            [25, 66],
            [20, 68],
            [15, 70],
            [12, 72],
            [11, 73],
            [10, 74],
            [9, 77],
            [8, 80],
            [7, 85],
            [6, 90],
            [5, 95],
            [4, 100],
            [0, 100],
        ],
        labelPos: [25, 70],
    },
    {
        label: 'Tid för tillväxt: 4 veckor',
        fill: '#ff6100',
        fillOpacity: 0.2,
        domainPoints: [
            [0, 100],
            [4, 100],
            [5, 97],
            [6, 94],
            [7, 91],
            [8, 88],
            [9, 86],
            [10, 84],
            [11, 82],
            [12, 81],
            [15, 80],
            [20, 79],
            [25, 78],
            [30, 77],
            [35, 76],
            [40, 75],
            [50, 74],
            [50, 68],
            [40, 69],
            [35, 70],
            [30, 71],
            [25, 72],
            [20, 73],
            [15, 74],
            [12, 75],
            [11, 76],
            [10, 78],
            [9, 81],
            [8, 84],
            [7, 88],
            [6, 92],
            [5, 96],
            [4, 100],
            [0, 100],
        ],
        labelPos: [25, 75 ],
    },
    {
        label: 'Hög mögelrisk',
        fill: '#ff0000',
        fillOpacity: 0.2,
        domainPoints: [
            [0, 100],
            [50, 100],
            [50, 74],
            [40, 75],
            [35, 76],
            [30, 77],
            [25, 78],
            [20, 79],
            [15, 80],
            [12, 81],
            [11, 82],
            [10, 84],
            [9, 86],
            [8, 88],
            [7, 91],
            [6, 94],
            [5, 97],
            [4, 100],
            [0, 100],
        ],
        labelPos: [25, 85],
    },
];

// Renders each zone as a filled polygon plus a label
function renderZones(props: any) {
    const { xAxisMap, yAxisMap } = props;
    if (!xAxisMap || !yAxisMap) return null;

    const xScale = xAxisMap[0].scale;
    const yScale = yAxisMap[0].scale;

    return (
        <g>
            {zoneDefinitions.map((zone, i) => {
                // Convert domain points to SVG points
                const svgPoints = zone.domainPoints.map(([tx, hy]) => [
                    xScale(tx),
                    yScale(hy),
                ]);

                // Build the "M...L...Z" path string
                const pathD =
                    `M${svgPoints[0][0]},${svgPoints[0][1]}` +
                    svgPoints
                        .slice(1)
                        .map(([x, y]) => `L${x},${y}`)
                        .join('') +
                    'Z';

                // Convert the zone's labelPos from domain coords -> SVG coords
                const [lx, ly] = [xScale(zone.labelPos[0]), yScale(zone.labelPos[1])];

                return (
                    <g key={i}>
                        <path
                            d={pathD}
                            fill={zone.fill}
                            fillOpacity={zone.fillOpacity}
                            stroke="none"
                        />
                        <text
                            x={lx}
                            y={ly}
                            fill="#333"
                            fontSize={12}
                            textAnchor="middle"
                            alignmentBaseline="middle"
                        >
                            {zone.label}
                        </text>
                    </g>
                );
            })}
        </g>
    );
}

export default function MoldRiskChart({ data }: { data: Array<{ x: number; y: number }> }) {
    return (
        <div className="bg-white p-4 rounded-xl shadow-md mt-6">
            <h2 className="text-xl font-semibold mb-4">Mögelriskdiagram</h2>
            {data.length === 0 ? (
                <p className="text-center text-gray-500">Ingen data för vald period.</p>
            ) : (
                <ResponsiveContainer width="100%" height={400}>
                    <ScatterChart margin={{ top: 20, right: 30, left: 10, bottom: 30 }}>
                        <CartesianGrid strokeDasharray="3 3" />

                        <XAxis
                            type="number"
                            dataKey="x"
                            name="Temperatur"
                            unit="°C"
                            domain={[0, 50]}
                        />
                        <YAxis
                            type="number"
                            dataKey="y"
                            name="Luftfuktighet"
                            unit="%"
                            domain={[50, 100]}
                        />
                        <Tooltip cursor={{ strokeDasharray: '3 3' }} />

                        {/* <Customized> lets us inject arbitrary SVG (our zone polygons). */}
                        <Customized component={renderZones} />

                        {/* The actual scatter points */}
                        <Scatter name="Temp vs. RH" data={data} fill="#3B82F6" />
                    </ScatterChart>
                </ResponsiveContainer>
            )}
        </div>
    );
}