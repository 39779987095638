import { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import dayjs from 'dayjs';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Button, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import {
    Thermometer,
    Droplet,
    MapPin,
    ArrowUp,
    ArrowDown
} from 'lucide-react';
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer,
    Legend,
} from 'recharts';
import MoldRiskChart from "./MoldRiskChart";

interface Sensor {
    name: string;
    id: string;
    temperature?: number;
    humidity?: number;
    maxt?: number;
    mint?: number;
    maxh?: number;
    minh?: number;
    loading?: boolean;
}

const sensors: Sensor[] = [
    { name: 'Stora huset - Kök', id: '151' },
    { name: 'Stora huset - Loft', id: '199' },
    { name: 'Grunden', id: '167' },
    { name: 'Lilla huset', id: '135' },
    { name: 'Norr', id: '200' },
    { name: 'Söder', id: '152' }
];

const getHumidityColor = (humidity?: number) => {
    if (humidity === undefined || humidity < 0) return 'text-gray-400';
    if (humidity < 30) return 'text-blue-500';
    if (humidity < 60) return 'text-green-600';
    if (humidity < 70) return 'text-orange-500';
    return 'text-red-500';
};

const getTemperatureColor = (temperature?: number) => {
    if (temperature === undefined) return 'text-gray-400';
    if (temperature < 0) return 'text-blue-600';
    if (temperature < 10) return 'text-light-blue-500';
    if (temperature < 20) return 'text-green-600';
    if (temperature < 30) return 'text-orange-500';
    return 'text-red-600';
};

// Helper to format a Date object as YYYY-MM-DD
const formatDate = (date: Date): string => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
};

export default function TemperatureApp() {
    // Default dates: from is today minus 3 months, to is today
    const today = new Date();
    const threeMonthsAgo = new Date();
    threeMonthsAgo.setMonth(threeMonthsAgo.getMonth() - 3);

    const [sensorData, setSensorData] = useState<Sensor[]>(
        sensors.map(sensor => ({ ...sensor, loading: true }))
    );
    const [chartData, setChartData] = useState<any[]>([]);
    const [selectedSensorId, setSelectedSensorId] = useState<string>('151');
    const [fromDate, setFromDate] = useState<string>(formatDate(threeMonthsAgo));
    const [toDate, setToDate] = useState<string>(formatDate(today));

    useEffect(() => {
        fetchCurrentData();
        fetchMinMaxData();
        // Fetch chart data for the selected sensor and default date range on sensor change
        fetchChartData(selectedSensorId, fromDate, toDate);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedSensorId]);

    useEffect(() => {
        if (chartData.length > 0) {
            console.log('Keys in chartData:', Object.keys(chartData[0]));
        }
    }, [chartData]);

    const fetchCurrentData = async () => {
        for (const sensor of sensors) {
            try {
                const response = await axios.post('/rest/readings.php', {
                    id: sensor.id,
                    site: 1,
                    type: 'curr'
                });
                const data = response.data[0];
                setSensorData(prevData =>
                    prevData.map(item =>
                        item.id === sensor.id
                            ? {
                                ...item,
                                temperature: parseFloat(data.temperature),
                                humidity:
                                    parseFloat(data.humidity) === -1
                                        ? undefined
                                        : parseFloat(data.humidity),
                                loading: false
                            }
                            : item
                    )
                );
            } catch (error) {
                console.error(`Error fetching current data for ${sensor.name}:`, error);
            }
        }
    };

    const fetchMinMaxData = async () => {
        for (const sensor of sensors) {
            try {
                const response = await axios.post('/rest/readings.php', {
                    id: sensor.id,
                    site: 1,
                    type: 'maxmin'
                });
                const data = response.data[0];
                setSensorData(prevData =>
                    prevData.map(item =>
                        item.id === sensor.id
                            ? {
                                ...item,
                                maxt: parseFloat(data.maxt),
                                mint: parseFloat(data.mint),
                                maxh: parseFloat(data.maxh),
                                minh: parseFloat(data.minh)
                            }
                            : item
                    )
                );
            } catch (error) {
                console.error(`Error fetching min/max data for ${sensor.name}:`, error);
            }
        }
    };

    // Fetch chart data with start and end dates
    const fetchChartData = async (
        sensorId: string,
        start: string,
        end: string
    ) => {
        try {
            const response = await axios.post('/rest/sensor_data_api.php', {
                id: sensorId,
                site: 1,
                type: 'chart_humidity',
                start,
                end
            });
            // Normalize response to an array if necessary
            const data = Array.isArray(response.data) ? response.data : [response.data];
            console.log('Fetched chart data:', data);
            setChartData(data);
        } catch (error) {
            console.error('Error fetching chart data:', error);
        }
    };

    // Validate date range and fetch chart data on button click
    const handleDateChange = () => {
        const startDate = new Date(fromDate);
        const endDate = new Date(toDate);
        const diffTime = endDate.getTime() - startDate.getTime();
        const diffDays = diffTime / (1000 * 60 * 60 * 24);

        if (diffDays < 1 || diffDays > 365) {
            alert('Please select a period between 1 day and 1 year.');
            return;
        }
        fetchChartData(selectedSensorId, fromDate, toDate);
    };

    // Process chart data to ensure numeric fields are numbers
    const processedChartData = useMemo(() => {
        if (!Array.isArray(chartData)) return [];
        return chartData.map(d => ({
            ...d,
            maxt: Number(d.maxt),
            mint: Number(d.mint),
            maxh: Number(d.maxh),
            minh: Number(d.minh),
            avgt: Number(d.avgt),
            avgh: Number(d.avgh)
        }));
    }, [chartData]);

    // Precompute the temperature domain for the left Y-axis
    const minTemp = useMemo(() => {
        if (processedChartData.length === 0) return 0;
        const validMins = processedChartData.map(d => d.mint).filter(n => !isNaN(n));
        return validMins.length > 0 ? Math.floor(Math.min(...validMins) - 2) : 0;
    }, [processedChartData]);

    const maxTemp = useMemo(() => {
        if (processedChartData.length === 0) return 50;
        const validMaxes = processedChartData.map(d => d.maxt).filter(n => !isNaN(n));
        return validMaxes.length > 0 ? Math.ceil(Math.max(...validMaxes) + 2) : 50;
    }, [processedChartData]);

    // Precompute the humidity domain for the right Y-axis
    const minHumidity = useMemo(() => {
        if (processedChartData.length === 0) return 0;
        const validMins = processedChartData.map(d => d.minh).filter(n => !isNaN(n));
        return validMins.length > 0 ? Math.floor(Math.min(...validMins) - 2) : 0;
    }, [processedChartData]);

    const maxHumidity = useMemo(() => {
        if (processedChartData.length === 0) return 100;
        const validMaxes = processedChartData.map(d => d.maxh).filter(n => !isNaN(n));
        return validMaxes.length > 0
            ? Math.ceil(Math.max(...validMaxes) + 2)
            : 100;
    }, [processedChartData]);

    // Create scatter data for the MoldRiskChart
    const scatterData = useMemo(() => {
        if (processedChartData.length === 0) return [];
        return processedChartData
            .filter(d => !isNaN(d.avgt) && !isNaN(d.avgh))
            .map(d => ({
                x: d.avgt,   // average temperature
                y: d.avgh,   // average humidity
                date: d.date
            }));
    }, [processedChartData]);

    return (
        <div className="min-h-screen bg-gradient-to-b from-gray-100 to-gray-200">
            <div className="max-w-screen-xl mx-auto px-4 py-8">
                {/* Page Title */}
                <h1 className="text-3xl font-bold mb-6 text-blue-800">Båta övervakning </h1>

                {/* Sensor Cards */}
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
                    {sensorData.map((sensor, index) => (
                        <div
                            key={index}
                            className="bg-white shadow-md rounded-2xl p-4 transition-transform transform hover:scale-105 hover:shadow-lg"
                            style={{ minHeight: '160px' }}
                        >
                            <div className="mb-2">
                                <h2 className="text-lg font-semibold mb-1 flex items-center text-gray-800">
                                    <MapPin className="mr-1 text-blue-600 w-4 h-4" />
                                    {sensor.name}
                                </h2>
                            </div>

                            <div
                                className={`flex items-center text-xl mb-1 ${getTemperatureColor(
                                    sensor.temperature
                                )}`}
                            >
                                <Thermometer className="mr-1 w-4 h-4" />
                                <span>{sensor.temperature ?? '-'}°C</span>
                            </div>

                            {sensor.humidity !== undefined && (
                                <div
                                    className={`flex items-center text-lg ${getHumidityColor(
                                        sensor.humidity
                                    )}`}
                                >
                                    <Droplet className="mr-1 w-4 h-4" />
                                    <span>{sensor.humidity}%</span>
                                </div>
                            )}

                            <div className="mt-2 grid grid-cols-1 gap-1 text-sm text-gray-500 font-normal">
                                {sensor.maxt !== undefined && (
                                    <div className="flex items-center">
                                        <ArrowUp className="mr-1 w-3 h-3 text-red-500" />
                                        <span>Max Temp: {sensor.maxt}°C</span>
                                    </div>
                                )}
                                {sensor.mint !== undefined && (
                                    <div className="flex items-center">
                                        <ArrowDown className="mr-1 w-3 h-3 text-blue-500" />
                                        <span>Min Temp: {sensor.mint}°C</span>
                                    </div>
                                )}
                                {sensor.maxh !== undefined && sensor.maxh !== -1 && (
                                    <div className="flex items-center">
                                        <ArrowUp className="mr-1 w-3 h-3 text-green-600" />
                                        <span>Max Humidity: {sensor.maxh}%</span>
                                    </div>
                                )}
                                {sensor.minh !== undefined && sensor.minh !== -1 && (
                                    <div className="flex items-center">
                                        <ArrowDown className="mr-1 w-3 h-3 text-green-600" />
                                        <span>Min Humidity: {sensor.minh}%</span>
                                    </div>
                                )}
                            </div>
                        </div>
                    ))}
                </div>

                {/* Chart Controls */}
                <div className="bg-white p-4 rounded-xl shadow-md my-6">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <div className="flex flex-wrap items-end gap-4">
                            {/* Sensor Selection */}
                            <FormControl variant="outlined" size="small">
                                <InputLabel>Sensor</InputLabel>
                                <Select
                                    label="Sensor"
                                    value={selectedSensorId}
                                    onChange={(e) => setSelectedSensorId(e.target.value)}
                                    style={{ minWidth: 150 }}
                                >
                                    {sensors.map(sensor => (
                                        <MenuItem key={sensor.id} value={sensor.id}>
                                            {sensor.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            {/* From Date */}
                            <DatePicker
                                label="Från"
                                value={dayjs(fromDate)}
                                onChange={(newValue) => {
                                    if (newValue) setFromDate(newValue.format('YYYY-MM-DD'));
                                }}
                                format="YYYY-MM-DD"
                                slotProps={{ textField: { size: 'small' } }}
                            />

                            {/* To Date */}
                            <DatePicker
                                label="Till"
                                value={dayjs(toDate)}
                                onChange={(newValue) => {
                                    if (newValue) setToDate(newValue.format('YYYY-MM-DD'));
                                }}
                                format="YYYY-MM-DD"
                                slotProps={{ textField: { size: 'small' } }}
                            />

                            {/* Update Button */}
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleDateChange}
                                style={{ height: '40px' }}
                            >
                                Uppdatera
                            </Button>
                        </div>
                    </LocalizationProvider>
                </div>

                {/* Line Chart Container */}
                <div className="bg-white p-4 rounded-xl shadow-md">
                    {processedChartData.length === 0 ? (
                        <p className="text-center text-gray-500">
                            No data available for the selected period.
                        </p>
                    ) : (
                        <ResponsiveContainer width="100%" height={400}>
                            <LineChart
                                data={processedChartData}
                                margin={{ top: 20, right: 30, left: 10, bottom: 30 }}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis
                                    dataKey="date"
                                    angle={-30}
                                    textAnchor="end"
                                    tick={{ fontSize: 12 }}
                                    interval="preserveEnd"
                                />
                                <YAxis
                                    yAxisId="left"
                                    domain={[minTemp, maxTemp]}
                                    allowDecimals={false}
                                    tickFormatter={(value) => `${value}°C`}
                                />
                                <YAxis
                                    yAxisId="right"
                                    orientation="right"
                                    domain={[minHumidity, maxHumidity]}
                                    allowDecimals={false}
                                    tickFormatter={(value) => `${value}%`}
                                />
                                <Tooltip />
                                <Legend verticalAlign="top" align="center" />
                                <Line
                                    type="monotone"
                                    dataKey="maxt"
                                    stroke="#EF4444" // red-500
                                    name="Max Temp"
                                    yAxisId="left"
                                    strokeWidth={2}
                                />
                                <Line
                                    type="monotone"
                                    dataKey="mint"
                                    stroke="#3B82F6" // blue-500
                                    name="Min Temp"
                                    yAxisId="left"
                                    strokeWidth={2}
                                />
                            </LineChart>
                        </ResponsiveContainer>
                    )}
                </div>

                {/* Mold Risk Chart */}
                <MoldRiskChart data={scatterData} />
            </div>
        </div>
    );
}